import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'

const BookSummaries = ({ location }) => {
  const configID = '3dUIFjpjLChZnWIDGBtr5w'

  const queryData = useStaticQuery(graphql`
    {
      allContentfulBookSummary(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            slug
            subTitle
            author
            publisher
            publishDate
            pages
            summary {
              json
            }
            purchaseUrl
            createdAt(formatString: "MMMM Do YYYY")
            bookCoverImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const TYPE = 'Book Summary'
  const IMAGE_HEIGHT = 250
  const data = queryData.allContentfulBookSummary.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.title,
    body: null,
    image: obj.node.bookCoverImage,
    imageHeight: IMAGE_HEIGHT,
    slug: `/resources/book-summaries/${obj.node.slug}`,
    category: obj.node.category,
    type: TYPE
  }))

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default BookSummaries
